@font-face {
    font-family: 'Fakt-Normal';
    src: url('./assets/fonts/Fakt-Normal.woff');
}

@font-face{
    font-family:'Fakt-Medium';
    src: url('./assets/fonts/Fakt-Medium.woff');
}

@font-face{
    font-family:'Fakt-Bold';
    src: url('./assets/fonts/Fakt-Bold.woff');
}

@font-face {
    font-family: 'AvenirLTPro-Roman';
    src: url('AvenirLTPro-Roman.ttf');
}

@font-face {
    font-family: 'Fieldwork-Geo-Light';
    src: url('Fieldwork-Geo-Light.ttf');
}

/* Montserrat */

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('./assets/fonts/Montserrat/static/Montserrat-Regular.ttf');
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('./assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf');
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('./assets/fonts/Montserrat/static/Montserrat-Bold.ttf');
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url('./assets/fonts/Montserrat/static/Montserrat-Medium.ttf');
}

* {
    font-family: 'Montserrat-Regular', Arial, Helvetica, sans-serif;
}

body, a{
    color: #424242; 
}

div.tooltip {
    display: block;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    position: absolute;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 3px 3px 2px rgba(0,0,0,0.2);
}